import { FormControl, FormLabel } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { MultiSelect } from 'react-multi-select-component';
import { IOption, useEvaluationSelectorStore } from 'stores/evaluationSelector';

export default function AlinhamentosSelector() {
  const loading = useEvaluationSelectorStore((state) => state.loading);
  const evaluations = useEvaluationSelectorStore((state) => state.evaluations);
  const selectedEvaluations = useEvaluationSelectorStore((state) => state.selectedAlinhamentosEvaluations);
  const setSelectedEvaluations = useEvaluationSelectorStore((state) => state.setSelectedAlinhamentosEvaluations);

  const options = useMemo(() => {
    return evaluations.map((evaluation) => {
      let label = evaluation.name;
      let value;

      if (evaluation.created_at) {
        label += ` (${new Date(evaluation.created_at).toLocaleDateString()})`;
      }

      if (evaluation.evaluation_id) {
        value = evaluation.evaluation_id;
      } else {
        value = evaluation.user_id;
      }

      return {
        label,
        value,
      };
    });
  }, [evaluations]);

  const selectedOptions = useMemo(() => {
    return selectedEvaluations.map((evaluation) => {
      let value;
      if (evaluation.evaluation_id) {
        value = evaluation.evaluation_id;
      } else {
        value = evaluation.user_id;
      }
      return {
        label: evaluation.name,
        value,
      };
    });
  }, [selectedEvaluations]);

  return (
    <FormControl w="18.75rem">
      <FormLabel fontSize="0.75rem">Representante</FormLabel>
      <MultiSelect
        disabled={loading}
        isLoading={loading}
        options={options}
        value={selectedOptions}
        hasSelectAll={false}
        onChange={(e: IOption[]) => {
          const last = e[e.length - 1];
          if (!last) return setSelectedEvaluations([]);
          const found = evaluations.find((ev) => ev.evaluation_id === last.value || ev.user_id === last.value);
          if (!found) return setSelectedEvaluations([]);
          setSelectedEvaluations([found]);
        }}
        labelledBy="Select"
        overrideStrings={{
          allItemsAreSelected: 'Todos Selecionados',
          clearSearch: 'Limpar',
          clearSelected: 'Limpar',
          noOptions: 'Sem opções',
          search: 'Procurar',
          selectAll: 'Selecionar Todos',
          selectAllFiltered: 'Selecionar Todos (Filtrado)',
          selectSomeItems: 'Colaboradores',
        }}
        ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
      />
    </FormControl>
  );
}
