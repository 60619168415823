import { Flex, Text } from '@chakra-ui/react';
import { SimpleDashboard } from 'layouts/dash/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import { CountResponses } from './types';

const options = [
  { color: '#E53422', min: 40, max: 50, description: 'Estresse percebido muito alto' },
  { color: '#FF8702', min: 27, max: 40, description: 'Estresse percebido alto' },
  { color: '#FFCE1F', min: 13, max: 27, description: 'Estresse percebido moderado' },
  { color: '#1CBF2E', min: 0, max: 13, description: 'Estresse percebido baixo' },
];

export default function Estresse(props: SimpleDashboard) {
  const { userId } = props;
  const [info, setInfo] = useState<CountResponses | null>(null);

  useEffect(() => {
    api
      .post(`/tableresponse/evaluations`, { evaluations: [userId] })
      .then(({ data }) => setInfo(data))
      .catch(() => toast.error('Erro ao buscar dados'));
  }, [userId]);

  if (!info) return null;

  return (
    <Flex direction="column" w="100%" h="100%" justifyContent="center" alignItems="center">
      <Flex alignItems="center" flexDirection="column" height="20rem" width="10rem" position="relative">
        {options.map((option, index) => (
          <Text
            position="absolute"
            right="0"
            key={index}
            top={`${1.8 + index * 3.5}rem`}
            fontWeight="600"
            transform="translateX(calc(100% - 2rem))"
            minWidth="max-content"
          >
            <Text as="span" fontWeight="800">{`${option.min}-${option.max} `}</Text>
            {`${option.description}`}
          </Text>
        ))}
        <Text
          fontWeight="700"
          fontSize="1.25rem"
          position="absolute"
          bottom={`${
            6 +
            3.4 *
              (info.responses[0].total < 13
                ? 0
                : info.responses[0].total < 27
                ? 1
                : info.responses[0].total < 40
                ? 2
                : 3)
          }rem`}
        >
          {info.responses[0].total}
        </Text>

        <svg viewBox="0 0 640 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M303.301 1.5c-9.3 1.7-16.2 4-24.8 8.3-25.5 12.7-43.2 34.5-50.7 62.3l-2.3 8.4-.5 423.199-.5 423.2-8.5 5.6c-43.7 29.2-71.4 70.701-82.1 123.301-2.7 13-3.6 42.3-1.9 57 9.4 79.7 68.6 145.2 147.1 162.6 99 21.9 198.2-38.5 223.8-136.5 5-19.1 5.6-24.7 5.6-48.9 0-23.9-.8-30.1-6.1-49.9-9.1-33.7-29.9-67.301-56-90.301-8.5-7.6-21.3-17.1-28.1-20.9l-4.3-2.4.5-482-.3-76.3-.2-76.2v-116.3c0-85-.2-91.8-1.9-100.2-3.8-18.6-12.7-34.5-27-48.5-21.9-21.3-52.4-30.9-81.8-25.5m34 34.9c18.9 6 34 20.9 40.5 40l2.7 8.1.3 431.199.2 431.2 11.8 6.9c31 18.4 50.2 38.1 64.6 66.401 25.4 49.8 22.7 107.9-7.2 154.3-22.8 35.5-58 59.5-100.5 68.7-15 3.2-44.6 3.2-60 0-48.4-10.2-87-39.9-109.2-84.1-21-41.9-20.8-94.7.5-137.2 14.2-28.501 35.3-50.401 65.4-68.001l12.6-7.3v-426.6c0-272.9.4-428.9 1-433.3 2.1-14 7.5-24.9 17.8-35.2 6.7-6.7 17.9-13.3 26.2-15.3 2.5-.6 5.4-1.4 6.5-1.6 3.8-.9 22 .3 26.8 1.8"
            fill="#000"
          />

          <path fill="#000" d="M414 160h65v25h-65zm0 225h65v25h-65zm0 225h65v25h-65zm0 225h65v25h-65z" />

          <path
            d="M366.959 84.911v177.435h-93.8V84.911c10.478-41.467 83.318-40.962 93.8 0"
            fill={options[0].color}
            fillOpacity={info.responses[0].total >= options[0].min ? 1 : 0.25}
          />
          <path
            d="M366.959 495.976V268.414h-93.8v227.562z"
            fill={options[1].color}
            fillOpacity={info.responses[0].total >= options[1].min ? 1 : 0.25}
          />
          <path
            d="M366.959 502.045h-93.8v227.562h93.8z"
            fill={options[2].color}
            fillOpacity={info.responses[0].total >= options[2].min ? 1 : 0.25}
          />
          <path
            d="M273.159 959.46V735.675h93.8V959.46c45.591 15.575 80.176 56.54 90.447 102.45 3.459 15.38 3.459 40.56 0 55.53-2.83 12.74-6.288 22.15-11.948 32.97-19.808 38.23-57.538 65.34-101.346 72.92-13.101 2.23-35.11 2.23-48.211 0-56.804-9.81-100.612-50.77-113.399-105.89-3.353-14.87-3.353-40.05.105-55.53 10.271-45.91 44.857-84.043 90.552-99.618z"
            fill={options[3].color}
            fillOpacity={info.responses[0].total >= options[3].min ? 1 : 0.25}
          />
        </svg>
      </Flex>
      <Text zIndex="100" fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
        Termômetro de Estresse Percebido
      </Text>
      <Text
        zIndex="100"
        as="i"
        fontWeight="400"
        align="center"
        marginTop="0.625rem"
        fontSize="0.875rem"
        maxWidth="700"
        color="gray.500"
      >
        O gráfico mostra seu nível de estresse com base na Escala de Estresse Percebido (PSS). A pontuação indica o
        quanto você percebe o estresse – quanto maior o número, maior o nível de estresse percebido.
      </Text>
    </Flex>
  );
}
