import { useDisclosure } from '@chakra-ui/hooks';
import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useAuthStore } from 'stores/authStore';
import { useEvaluationSelectorStore } from 'stores/evaluationSelector';
import AlinhamentosSelector from './AlinhamentosSelector';
import CompanySelector from './CompanySelector';
import CourseSelector from './CourseSelector';
import EvaluationSelectorDiv from './EvaluationSelector';
import ScannerSelector from './ScannerSelector';
import SectorSelector from './SectorSelector';

export default function EvaluationSelector() {
  const user = useAuthStore((state) => state.user);
  const clearSelected = useEvaluationSelectorStore((state) => state.clearSelected);
  const setSelectedCompanies = useEvaluationSelectorStore((state) => state.setSelectedCompanies);
  const setSelectedEvaluations = useEvaluationSelectorStore((state) => state.setSelectedEvaluations);
  const setEvaluations = useEvaluationSelectorStore((state) => state.setEvaluations);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checked, setChecked] = useState<number>(0);
  const { id } = useParams<{ id: string }>();

  const onlySector = useMemo(() => {
    return ['10', '8'].includes(id);
  }, [id]);

  const onlyCompany = useMemo(() => {
    return ['14'].includes(id);
  }, [id]);

  useEffect(() => {
    if (user.type_account !== 2) return;
    setSelectedCompanies([
      {
        id: user.company_id,
        commercial_name: user.company_name ?? '',
      },
    ]);
  }, [user]);

  useEffect(() => {
    setSelectedEvaluations([]);
    setEvaluations([]);
  }, [id]);

  return (
    <>
      <Flex id="evaluation-header" gap="1rem">
        <Button colorScheme="yellow" onClick={onOpen} w="max-content">
          Filtros
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="max-content" userSelect="none">
          <ModalHeader>Filtros</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column" gap="1rem">
            <Flex maxW="18.75rem">
              {['Empresa', 'Treinamento'].map((text, index) => (
                <Fragment key={index}>
                  <Checkbox
                    ml={index === 1 ? 'auto' : '0'}
                    size="sm"
                    isChecked={checked === index}
                    onChange={() => {
                      clearSelected();
                      setChecked(index);
                    }}
                  />
                  <Text fontSize="0.75rem" ml="0.25rem">
                    {text}
                  </Text>
                </Fragment>
              ))}
            </Flex>

            {user.type_account === 1 && checked === 0 && <CompanySelector />}

            {user.type_account === 1 && checked === 1 && <CourseSelector />}

            {checked === 0 && !onlyCompany && <SectorSelector />}

            {!onlySector && !onlyCompany && <EvaluationSelectorDiv />}

            {id === '15' && <AlinhamentosSelector />}

            {id === '-1' && <ScannerSelector />}

            <Button onClick={clearSelected} colorScheme="yellow" mt="auto" maxW="18.75rem">
              Limpar
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
