import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Chart, LineElement, PointElement, SubTitle, Title } from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { createPortal } from 'react-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useEvaluationSelectorStore } from 'stores/evaluationSelector';
import questions from '../../../Questions/Others/QuestionAlinhamentoDeValores/questions.json';

Chart.register(PointElement, LineElement, Annotation, Title, SubTitle);

// function distanceFromPercentage(r: number, percentage: number): number {
//   console.log(r, percentage);
//   if (percentage >= 100) {
//     // Fully overlapping
//     return 0;
//   } else if (percentage <= 0) {
//     // No overlap
//     return 2 * r;
//   } else {
//     // Partial overlap
//     const targetIntersectionArea = (percentage / 100) * (Math.PI * r * r);

//     // Define a function to calculate the intersection area for a given x
//     const intersectionArea = (x: number): number => {
//       return 2 * r * r * Math.acos(x / (2 * r)) - (x / 2) * Math.sqrt(4 * r * r - x * x);
//     };

//     // Use a numerical approach to find x (binary search)
//     let low = 0;
//     let high = 2 * r;
//     let mid: number;

//     while (high - low > 1e-6) {
//       // Precision threshold
//       mid = (low + high) / 2;
//       const area = intersectionArea(mid);

//       if (area < targetIntersectionArea) {
//         low = mid;
//       } else {
//         high = mid;
//       }
//     }

//     return (low + high) / 2; // The estimated distance x
//   }
// }

// const data = useMemo(() => {
//   const intersection:  = [];
//   const companyOnly: number[] = [];
//   const passed: number[] = [];
//   desEquipeData?.company?.forEach((questionIndex, companyPriority) => {
//     const employeePriority = desEquipeData?.employee?.indexOf(questionIndex);
//     if (employeePriority >= 0) {
//       intersection.push({
//         companyPriority: companyPriority,
//         employeePriority: employeePriority,
//         questionIndex: questionIndex,
//       });
//       passed.push(questionIndex);
//     } else {
//       companyOnly.push(questionIndex);
//     }
//   });
//   intersection.sort((a, b) => a.questionIndex - b.questionIndex);
//   return { intersection, companyOnly, employeeOnly: desEquipeData.employee.filter((q) => !passed.includes(q)) };
// }, [desEquipeData]);

type TAlignmentPageData = {
  intersection: { companyPriority: number; employeePriority: number; questionIndex: number }[];
  companyOnly: (number | undefined)[];
  employeeOnly: (number | undefined)[];
};

type TAlignmentData = {
  values: TAlignmentPageData;
  necessities: TAlignmentPageData;
};

const defaultData: TAlignmentData = {
  values: {
    intersection: [],
    companyOnly: [],
    employeeOnly: [],
  },
  necessities: {
    intersection: [],
    companyOnly: [],
    employeeOnly: [],
  },
};

const graphs = ['Alinhamento dos valores', 'Alinhamento das necessidades', 'Lista de prioridades'];

const test = {
  values: {
    companyOnly: [null, 7, null, 10, 13, null, null, null, null, null],
    employeeOnly: [0, null, 2, null, 4, null, null, null, null, null],
    intersection: [
      {
        companyPriority: 2,
        employeePriority: 1,
        questionIndex: 1,
      },
      {
        companyPriority: 5,
        employeePriority: 3,
        questionIndex: 3,
      },
      {
        companyPriority: 0,
        employeePriority: 5,
        questionIndex: 5,
      },
      {
        companyPriority: 6,
        employeePriority: 6,
        questionIndex: 6,
      },
      {
        companyPriority: 8,
        employeePriority: 7,
        questionIndex: 8,
      },
      {
        companyPriority: 9,
        employeePriority: 8,
        questionIndex: 9,
      },
      {
        companyPriority: 7,
        employeePriority: 9,
        questionIndex: 12,
      },
    ],
  },
  necessities: {
    companyOnly: [null, null, null, null, null, 16, 32, null, 25, 19],
    employeeOnly: [0, 1, 2, 3, null, null, null, null, null, null],
    intersection: [
      {
        companyPriority: 1,
        employeePriority: 4,
        questionIndex: 4,
      },
      {
        companyPriority: 4,
        employeePriority: 5,
        questionIndex: 5,
      },
      {
        companyPriority: 3,
        employeePriority: 6,
        questionIndex: 6,
      },
      {
        companyPriority: 2,
        employeePriority: 7,
        questionIndex: 8,
      },
      {
        companyPriority: 7,
        employeePriority: 8,
        questionIndex: 9,
      },
      {
        companyPriority: 0,
        employeePriority: 9,
        questionIndex: 12,
      },
    ],
  },
};

export default function AlinhamentoChart(): JSX.Element {
  const selectedEv = useEvaluationSelectorStore((state) => state.selectedEvaluations);
  const selectedRep = useEvaluationSelectorStore((state) => state.selectedAlinhamentosEvaluations);
  const [showGraph, setShowGraph] = useState<number>(0);
  const [desEquipeData, setDesEquipeData] = useState<TAlignmentData>(defaultData);

  useEffect(() => {
    console.log(selectedEv, selectedRep);
    setDesEquipeData(defaultData);
    if (!selectedEv[0]?.evaluation_id || !selectedRep[0]?.evaluation_id) return;
    api
      .post(`/tableresponse/evaluations`, { evaluations: [selectedEv[0].evaluation_id, selectedRep[0].evaluation_id] })
      .then(({ data }) => {
        console.log(data);
        setDesEquipeData(data);
      })
      .catch(() => {
        toast.error('Erro ao buscar dados');
        setDesEquipeData(defaultData);
      });
  }, [selectedEv, selectedRep]);

  const header = document.getElementById('evaluation-header');

  const data = showGraph === 0 ? desEquipeData.values : desEquipeData.necessities;

  if (
    !selectedEv[0]?.evaluation_id ||
    !selectedRep[0]?.evaluation_id ||
    selectedRep[0]?.evaluation_id === selectedEv[0]?.evaluation_id
  )
    return <></>;

  return (
    <Flex width="100%" height="100%" overflow="auto">
      {!!header &&
        createPortal(
          <>
            {graphs.map((graph, index) => (
              <Button
                ml={index === 0 ? 'auto' : ''}
                colorScheme={showGraph === index ? 'yellow' : 'blackAlpha'}
                onClick={() => setShowGraph(index)}
                w="max-content"
              >
                {graph}
              </Button>
            ))}
          </>,
          header,
        )}
      {showGraph === 2 ? (
        [desEquipeData.values, desEquipeData.necessities].map((data, index) => {
          return (
            <Flex width="50%" padding="0 2rem" flexDir="column" alignItems="center" key={index} marginTop="2rem">
              <Flex
                boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
                borderRadius={'0.3125rem'}
                overflowY="auto"
                maxHeight="min(37.5rem, 100%)"
                alignSelf="flex-start"
                style={{ scrollbarWidth: 'thin' }}
              >
                <Table variant="striped">
                  <Thead position="sticky" top="0">
                    <Tr bgColor={'white'}>
                      <Th>{index === 0 ? 'Valor' : 'Necessidade'}</Th>
                      <Th>Ranqueamento indivíduo</Th>
                      <Th>Ranqueamento empresa</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.companyOnly.map((question, rank) => {
                      if (question == undefined) return null;
                      return (
                        <Tr key={rank}>
                          <Td>{questions[index].questions[question]?.title}</Td>
                          <Td>-</Td>
                          <Td>{rank + 1}</Td>
                        </Tr>
                      );
                    })}
                    {data.employeeOnly.map((question, rank) => {
                      if (question == undefined) return null;
                      return (
                        <Tr key={rank}>
                          <Td>{questions[index].questions[question]?.title}</Td>
                          <Td>{rank + 1}</Td>
                          <Td>-</Td>
                        </Tr>
                      );
                    })}
                    {data.intersection.map((question, rank) => (
                      <Tr key={rank}>
                        <Td>{questions[index].questions[question.questionIndex]?.title}</Td>
                        <Td>{question.employeePriority + 1}</Td>
                        <Td>{question.companyPriority + 1}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>

              <Text zIndex="100" fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
                {index === 0 ? 'Alinhamento dos Valores' : 'Alinhamento das Necessidades'}
              </Text>
              <Text
                zIndex="100"
                as="i"
                fontWeight="400"
                align="center"
                marginTop="0.625rem"
                fontSize="0.875rem"
                maxWidth="30rem"
                color="gray.500"
              >
                {index === 0
                  ? 'As tabelas mostram os valores priorizados pelo indivíduo e pela empresa, com notas de 1 a 10 indicando a ordem de importância. O traço ("-") representa itens escolhidos por apenas uma das partes.'
                  : 'As tabelas mostram as necessidades priorizadas pelo indivíduo e pela empresa, com notas de 1 a 10 indicando a ordem de importância. O traço ("-") representa itens escolhidos por apenas uma das partes.'}
              </Text>
            </Flex>
          );
        })
      ) : (
        <>
          <Flex
            justifyContent="start"
            alignItems="center"
            flexDir="column"
            width={{ base: '100%', lg: '50%' }}
            height="100%"
            minHeight="25rem"
            pt="5rem"
          >
            <Box minHeight="25rem" height="100%" maxHeight="30rem" width="100%">
              <Line
                data={{
                  labels: [
                    '',
                    ...data.intersection.map((d) => questions[showGraph].questions[d.questionIndex]?.title),
                    '',
                  ],
                  datasets: [
                    {
                      label: 'Empresa',
                      data: [null, ...(data.intersection.map((d) => d.companyPriority + 1) ?? [])],
                      borderColor: '#fdc300',
                      backgroundColor: '#fdc300',
                      borderWidth: 4,
                    },
                    {
                      label: 'Indivíduo',
                      data: [null, ...(data.intersection.map((d) => d.employeePriority + 1) ?? [])],
                      borderColor: '#565656',
                      backgroundColor: '#565656',
                      borderWidth: 4,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  interaction: {
                    intersect: false,
                    mode: 'index',
                  },
                  scales: {
                    y: {
                      suggestedMax: 11,
                      suggestedMin: 0,
                      ticks: {
                        stepSize: 1,
                        padding: 0,
                        crossAlign: 'center',
                        callback: (value) => (value === 0 || value === 11 ? '' : value),
                      },
                      grid: {
                        color: 'rgb(243, 243, 243)',
                      },
                      title: {
                        display: true,
                        text: 'Prioridade',
                      },
                    },
                    x: {
                      grid: {
                        color: 'rgb(243, 243, 243)',
                      },
                      title: {
                        display: true,
                        text: showGraph === 0 ? 'Valores' : 'Necessidades',
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        title: (t) => {
                          const label = t[0].label.split(',')[0];
                          return `Questão ${label}`;
                        },
                      },
                    },
                    datalabels: {
                      display: false,
                    },
                    title: {
                      display: false,
                      text: '',
                      position: 'bottom',
                      color: 'black',
                      font: {
                        weight: '700',
                        size: 20,
                      },
                    },
                    subtitle: {
                      display: false,
                      position: 'bottom',
                      text: [
                        'O gráfico compara as prioridades atribuídas aos valores ou necessidades compartilhados',
                        'pelo indivíduo (linha cinza) e pela empresa (linha amarela). O eixo horizontal exibe os ítens em',
                        'comum, e o eixo vertical mostra as notas de 1 a 10, indicando a importância de cada item. Linhas',
                        'próximas refletem alinhamento, enquanto linhas distantes destacam diferenças de percepção.',
                      ],
                      color: 'black',
                      font: {
                        weight: '500',
                        size: 16,
                      },
                    },
                  },
                  layout: {
                    padding: {
                      left: 20,
                    },
                  },
                }}
              />
            </Box>

            <Text zIndex="100" fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
              {showGraph === 0 ? 'Alinhamento dos Valores' : 'Alinhamento das Necessidades'}
            </Text>
            <Text
              zIndex="100"
              as="i"
              fontWeight="400"
              align="center"
              marginTop="0.625rem"
              fontSize="0.875rem"
              maxWidth="30rem"
              color="gray.500"
            >
              {showGraph === 0
                ? 'O gráfico compara as prioridades atribuídas aos valores ou necessidades compartilhados pelo indivíduo (linha cinza) e pela empresa (linha amarela). O eixo horizontal exibe os ítens em comum, e o eixo vertical mostra as notas de 1 a 10, indicando a importância de cada item. Linhas próximas refletem alinhamento, enquanto linhas distantes destacam diferenças de percepção.'
                : 'O gráfico compara as prioridades atribuídas aos valores ou necessidades compartilhados pelo indivíduo (linha cinza) e pela empresa (linha amarela). O eixo horizontal exibe os ítens em comum, e o eixo vertical mostra as notas de 1 a 10, indicando a importância de cada item. Linhas próximas refletem alinhamento, enquanto linhas distantes destacam diferenças de percepção.'}
            </Text>
          </Flex>
          <Flex position="relative" width="50%" height="100%" justifyContent="center" alignItems="start" pt="10rem">
            <Flex
              position="absolute"
              ml={`${((10 - data.intersection.length) * -15) / 10}rem`}
              // ml={`${distanceFromPercentage(15, (100 * (10 - data.intersection.length)) / 10)}rem`}
              bg="#56565680"
              width="15rem"
              height="15rem"
              rounded="100%"
              flexDir="column"
              alignItems="start"
              justifyContent="center"
            >
              {data.companyOnly.map((questionIndex, priority) => {
                if (questionIndex == undefined) return null;
                return (
                  <Text fontSize="0.75rem" transform="translateX(-100%)" key={priority}>
                    {questions[showGraph].questions[questionIndex]?.title}
                  </Text>
                );
              })}
            </Flex>
            <Flex
              position="absolute"
              mr={`${((10 - data.intersection.length) * -15) / 10}rem`}
              // mr={`${distanceFromPercentage(15, (100 * (10 - data.intersection.length)) / 10)}rem`}
              bg="#fdc30080"
              width="15rem"
              height="15rem"
              rounded="100%"
              flexDir="column"
              alignItems="end"
              justifyContent="center"
            >
              {data.employeeOnly.map((questionIndex, priority) => {
                if (questionIndex == undefined) return null;
                return (
                  <Text fontSize="0.75rem" transform="translateX(100%)" key={priority}>
                    {questions[showGraph].questions[questionIndex]?.title}
                  </Text>
                );
              })}
            </Flex>
            <Flex flexDir="column" alignItems="center" top="calc(10rem + 15rem)" position="absolute">
              {data.intersection.map((question) => (
                <Text fontSize="0.75rem" key={question.questionIndex}>
                  {questions[showGraph].questions[question.questionIndex]?.title}
                </Text>
              ))}
              <Text zIndex="100" fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
                {showGraph === 0 ? 'Alinhamento dos Valores' : 'Alinhamento das Necessidades'}
              </Text>
              <Text
                zIndex="100"
                as="i"
                fontWeight="400"
                align="center"
                marginTop="0.625rem"
                fontSize="0.875rem"
                maxWidth="30rem"
                color="gray.500"
              >
                {showGraph === 0
                  ? 'Mostra os valores escolhidos pelo indivíduo (cinza) e pela empresa (amarelo). A área em comum representa os itens compartilhados, enquanto as áreas separadas mostram os exclusivos de cada um.'
                  : 'Mostra as necessidades identificadas pelo indivíduo (cinza) e pela empresa (amarelo). A área em comum representa as necessidades compartilhadas, enquanto as áreas separadas mostram as exclusivas de cada um.'}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}
