import { AlertDialog, Button, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react';
import CustomAlert from 'components/CustomAlert';
import EvaluationSelector from 'components/EvaluationSelector';
import ReportLink from 'components/Reports/Drawer/ReportLink';
import ReportModal from 'components/Reports/Drawer/ReportModal';
import React, { useMemo, useRef } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaPrint } from 'react-icons/fa';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useEvaluationSelectorStore } from 'stores/evaluationSelector';
import { useLoadingStore } from 'stores/loadingStore';
import { generateImgLink } from 'utils/generateImgLink';
import { ConsolidatedChart, ConsolidatedReport, Report, SimpleDashboard, SimpleScannerDashboard } from './types';

interface IProps {
  title: string;
  Chart: React.FC<SimpleDashboard> | React.FC<SimpleScannerDashboard>;
  ConsolidatedChart?: React.FC<ConsolidatedChart>;
  Report?: React.FC<Report>;
  ConsolidatedReport?: React.FC<ConsolidatedReport>;
  isSector?: boolean;
}

function ScannerDash(props: IProps): JSX.Element {
  const { Chart, ConsolidatedChart, title, ConsolidatedReport, isSector } = props;
  const { id: evaluationType } = useParams<{ id: string }>();

  const responses = useRef<any>(undefined);
  const chartRefs = [useRef<any>(), useRef<any>(), useRef<any>(), useRef<any>()];
  const cancelRef = useRef<any>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  const { selectedEvaluations, selectedCompanies, selectedSectors } = useEvaluationSelectorStore();

  const selectedIds = useMemo(() => {
    if (evaluationType === '14') {
      return selectedCompanies.map((company) => company.id || '');
    }
    if (isSector) {
      return selectedSectors.map((sector) => sector.id);
    }
    return selectedEvaluations.map((evaluation) => evaluation.evaluation_id);
  }, [selectedEvaluations, selectedSectors, isSector, selectedCompanies]);

  function getName() {
    let first = '';
    if (isSector) {
      first = selectedSectors?.length > 1 ? 'Vários Setores' : selectedSectors?.[0]?.sector_name ?? 'Nenhum Setor';
    } else {
      first =
        selectedEvaluations?.length > 1 ? 'Várias Avaliações' : selectedEvaluations?.[0]?.name ?? 'Nenhuma Avaliação';
    }
    const second =
      selectedCompanies?.length > 1 ? 'Várias Empresas' : selectedCompanies?.[0]?.commercial_name ?? 'Nenhuma Empresa';
    return `${title} - ${first} - ${second}`;
  }

  async function handleDownloadPDF() {
    try {
      useLoadingStore.setState({ loading: true });
      const body: any = {
        evaluation_type: evaluationType,
        company_ids: selectedCompanies.map((company) => company.id),
      };

      switch (evaluationType) {
        case '15':
          // eslint-disable-next-line no-case-declarations
          const alinhamentoEv = useEvaluationSelectorStore
            .getState()
            .selectedAlinhamentosEvaluations.map((ev) => ev.evaluation_id);
          body.target_ids = [...selectedEvaluations.map((evaluation) => evaluation.evaluation_id), ...alinhamentoEv];
          break;
        case '12':
          body.target_ids = selectedEvaluations.map((ev) => ev.evaluation_id);
          break;
        default:
          body.target_ids = selectedEvaluations.map((evaluation) => evaluation.evaluation_id);
          body.selected_ids = selectedIds;
          break;
      }

      const res = await api.post('tableresponse/report/download', body, { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(res.data);

      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = `Avaliação ${getName()}.pdf`;
      alink.click();
    } catch (err) {
      console.error(err);
    }
    useLoadingStore.setState({ loading: false });
  }

  async function handleDownloadGraph() {
    const populated = chartRefs.filter((ref) => ref.current);
    populated.forEach(async (ref, index) => {
      if (!ref || !ref.current) return;
      const element = ref.current;
      const link = await generateImgLink(element);
      link.download = `Gráfico ${getName()}${populated.length > 1 ? ` - ${index + 1}` : ''}.png`;
      link.click();
    });
  }

  async function sendReport() {
    useLoadingStore.setState({ loading: true });
    if (!selectedEvaluations?.[0].user_id) return;

    api
      .post(`/evaluation/report`, {
        user_id: selectedEvaluations[0].user_id,
        evaluation_id: selectedEvaluations[0].evaluation_id,
        type_evaluation: evaluationType,
      })
      .then(() => toast.success('Relatório enviado com sucesso.'))
      .catch(() => toast.error('Erro ao enviar relatório.'))
      .finally(() => {
        useLoadingStore.setState({ loading: false });
        onClose2();
      });
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen2}
        leastDestructiveRef={cancelRef}
        onClose={onClose2}
        isCentered
        closeOnOverlayClick={true}
      >
        <CustomAlert title="Confirmação" onClose={onClose2} onNext={sendReport}>
          <Text>Você tem certeza que deseja enviar este relatório?</Text>
        </CustomAlert>
      </AlertDialog>
      <Flex w="100%" h="calc(100vh - 4rem)" flexDirection="column" paddingInline={'1rem'}>
        <Flex flexWrap={'wrap'} py={2}>
          <Heading fontSize="lg" color="gray.700" marginRight="auto">
            {title}
          </Heading>

          {evaluationType === '7' && (
            <Button
              ml="3"
              variant="outline"
              w="min"
              h="8"
              margin="0 0.75rem 0 0.75rem"
              fontSize="0.75rem"
              type="button"
              leftIcon={<FaPrint size={18} />}
              onClick={onOpen2}
              disabled={!selectedEvaluations.length}
            >
              Enviar Relatório
            </Button>
          )}

          <Button
            variant="outline"
            w="9.8125rem"
            h="8"
            margin="0 0 0 0.75rem"
            fontSize="0.75rem"
            leftIcon={<FaPrint size={18} />}
            onClick={onOpen}
            disabled={!selectedIds?.length}
          >
            Imprimir
          </Button>
        </Flex>

        <EvaluationSelector />

        <Flex width="100%" height="100%" overflow="hidden" paddingTop="1.25rem" paddingBottom="1.25rem">
          {selectedIds.length === 1 ? (
            <Chart chartRefs={chartRefs} userId={selectedIds[0]} reportOpen={isOpen} scanner={false} />
          ) : selectedIds.length > 1 && ConsolidatedChart ? (
            <ConsolidatedChart evaluations={selectedIds} responses={responses} chartRefs={chartRefs} />
          ) : null}
        </Flex>
      </Flex>
      <ReportModal title={`Impressão ${title}`} isOpen={isOpen} onClose={onClose}>
        <Flex flexDirection="column" gap="2">
          {/* Download Graph */}
          <Button
            variant="outline"
            w="9.8125rem"
            h="8"
            fontSize="0.75rem"
            leftIcon={<AiOutlineDownload />}
            disabled={!selectedIds?.length}
            onClick={handleDownloadGraph}
          >
            Download Gráfico
          </Button>

          {/* Download PDF*/}
          {selectedIds.length === 1 && (
            <Button
              variant="outline"
              w="9.8125rem"
              h="8"
              fontSize="0.75rem"
              leftIcon={<AiOutlineDownload />}
              disabled={!selectedIds?.length}
              onClick={handleDownloadPDF}
            >
              Download Relatório
            </Button>
          )}

          {/* Download PDF Consolidated*/}
          {selectedIds.length > 1 &&
            (ConsolidatedReport ? (
              <ReportLink
                Report={ConsolidatedReport}
                title={title}
                chartRefs={chartRefs}
                selectedCompany={selectedCompanies[0].commercial_name ?? ''}
                selectedUserName={selectedEvaluations?.[0]?.name ?? ''}
                responses={responses}
              />
            ) : (
              <Button
                variant="outline"
                w="9.8125rem"
                h="8"
                fontSize="0.75rem"
                leftIcon={<AiOutlineDownload />}
                disabled={!selectedIds?.length}
                onClick={handleDownloadPDF}
              >
                Download Relatório
              </Button>
            ))}
        </Flex>
      </ReportModal>
    </>
  );
}

export default ScannerDash;
